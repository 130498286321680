import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';


import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext';
import axios from 'axios';
import Header from './components/Header';

import {
  LoginPage,
  MainPage,
  SignIn,
  SignUp,
  ResetPasswordRequest,
  ResetPassword,
  ErrorPage,
  AccountManagement,
  Downloads,
  Support,
  NotFoundPage,
  Unauthorized,
} from './pages';

// Set withCredentials to true globally
axios.defaults.withCredentials = true;

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Header />
          <div className="content">
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<MainPage />} />
                <Route path="/downloads" element={<Downloads />} />
                <Route path="/support" element={<Support />} />
                <Route path="/account" element={<AccountManagement />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />  {/* Catch-all route for 404 */}
            </Routes>
          </div>
        </Router>
      </AuthProvider>
      <div className="App-footer">
        {/* Optional links or information */}
      </div>
    </div>
  );
}

export default App;
