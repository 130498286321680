import React from 'react';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

const ErrorPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const errorMessage = searchParams.get('message') || 'An error occurred';

  return (
    <div>
      <Typography variant="h4">401 - Unauthorized</Typography>
      &nbsp;
      <Typography>{errorMessage}</Typography>
    </div>
  );
};

export default ErrorPage;
