import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import '../components/CentralForm.css'

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const [requestSent, setRequestSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleResendClick = () => {
    setRequestSent(false);
    setErrorMessage(null);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (errorMessage) setErrorMessage('');
  };

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      console.log('sending', email)
      const response = await axios.post('/api/reset-password-request', { email });
      console.log('Received', response)
      setRequestSent(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Reset Password error:', error);
        if (error.response?.status === 404) {
          setErrorMessage("User not found.");
        } else {
          setErrorMessage(`Failed to reset password: ${error}`);
        }
      }
    }
    setIsLoading(false);
  };

  if (requestSent) {
    return (
      <div className='central-form-container'>
        <Typography variant="h4">Please Check Your Inbox</Typography>
        <Box mt={2}>
          <Typography align="left">
            We've sent an email with instructions to reset your password. 
            Please check your inbox and follow the instructions to set a new password.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography align="left">
            Didn't receive an email? <Link to="#" onClick={handleResendClick}>Resend</Link>
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography align="left">
            Remembered your password? <Link to="/signin">Sign In</Link>
          </Typography>
        </Box>
      </div>
    );
  }

  // Render loading state
  if (isLoading) {
    return (
      <div style={{ opacity: 0.5 }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className='central-form-container'>
      <Typography variant="h4">Reset Password</Typography>
      &nbsp;
      <TextField
        label="Email Address"
        type="email"
        autoComplete="email"
        fullWidth
        margin="normal"
        value={email}
        onChange={handleEmailChange}
        error={!!errorMessage}
        helperText={errorMessage}
      />
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetPassword}
          fullWidth
        >
          Reset Password
        </Button>
      </Box>
      <Typography mt={2}>
        Remembered your password? <Link to="/signin">Sign In</Link>
      </Typography>
    </div>
  );
};

export default ResetPassword;
