import React from 'react';
import Button from '@mui/material/Button';
import GitHubIcon from '@mui/icons-material/GitHub';

const LoginGitHub = () => {
  const handleLogin = () => {
    window.location.href = "https://femorph.com/auth/github";
    // window.location.href = "http://localhost:3001/auth/github";
  };

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      startIcon={<GitHubIcon />}
      onClick={handleLogin}>Login using GitHub
    </Button>
  );
};

export default LoginGitHub;
