import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <Box textAlign="center" mt={4}>
      <Typography variant="h3">404 - Page Not Found</Typography>
      <Typography variant="subtitle1">
        Oops! The page you are looking for does not exist.
      </Typography>
      <Box mt={2}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          Go Back Home
        </Link>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
