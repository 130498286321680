import React from 'react';

const EmailLink = () => {
  const email = "akascap@gmail.com";
  return <a href={`mailto:${email}`}>Alex Kaszynski</a>;
};

const Unauthorized: React.FC = () => {
  return (
    <div>
      <h1>Access Denied</h1>
      <p>
        You need to be registered with the {' '}
        <a href="https://github.com/femorph" target="_blank" rel="noopener noreferrer">femorph</a>
        {' '} organization on GitHub to access this application.
        Contact {' '}
        <EmailLink />
        {' '} for access.
      </p>
    </div>
  );
};

export default Unauthorized;
