import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PasswordStrengthBar, {PasswordFeedback} from 'react-password-strength-bar';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ResetPasswordForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordScore, setPasswordScore] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();

  // Use email from authenticated user context, skip token
  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email);
    } else {
      const queryParams = new URLSearchParams(location.search);
      setEmail(queryParams.get('email') || '');
      setToken(queryParams.get('token') || '');
    }
  }, [currentUser]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (errorMessage) setErrorMessage('');
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (errorMessage) setErrorMessage('');
  };

  const handleScoreChange = (score: number, feedback: PasswordFeedback) => {
    setPasswordScore(score);
  };

  const handleResetPassword = async () => {
    if (password.length < 12) {
      setErrorMessage('Password is too short. It must be at least 12 characters.');
      return;
    }

    if (passwordScore < 2) {
      setErrorMessage('Password is too weak.');
      return;
    }
    
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    if (currentUser) {
      await axios.post('/api/reset-password-authenticated', { email, password });
      navigate('/account');
    } else {
      try {
        await axios.post('/api/reset-password', { email, token, password });
        navigate(`/signin?email=${encodeURIComponent(email)}`);
      } catch (error) {
        console.error('Reset Password error:', error);
        setErrorMessage('Failed to reset password.');
      }
    }
  };

  return (
    <div className='central-form-container'>
      <Typography variant="h4">Reset Password</Typography>
      &nbsp;
      <TextField
        label="Email Address"
        type="email"
        fullWidth
        margin="normal"
        value={email}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        label="New Password"
        type="password"
        autoComplete="new-password"
        fullWidth
        margin="normal"
        value={password}
        onChange={handlePasswordChange}
        error={!!errorMessage}
        helperText={errorMessage}
      />
      <PasswordStrengthBar
        password={password}
        minLength={12}
        shortScoreWord={"Must be at least 12 characters"}
        onChangeScore={handleScoreChange}
      />
      <TextField
        label="Confirm New Password"
        type="password"
        autoComplete="new-password"
        fullWidth
        margin="normal"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        error={!!errorMessage}
      />
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetPassword}
          fullWidth
        >
          Reset Password
        </Button>
      </Box>
    </div>
  );
};

export default ResetPasswordForm;
