import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return null;
  }
  console.log('isAuthenticated:', isAuthenticated)

  if (!isAuthenticated) {
    // pass pre-authenticated path to login
    const redirectPath = `/login?preAuthRoute=${encodeURIComponent(location.pathname)}`;
    return <Navigate to={redirectPath} />;
  }

  return <Outlet />; // Render children routes when authenticated

};

export default ProtectedRoute;
