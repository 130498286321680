import React, { ChangeEvent, useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../components/CentralForm.css'

const AccountManagement: React.FC = () => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleChangePassword = () => {
    navigate('/reset-password');
  };

  const handleDeleteAccount = async () => {
    try {
      const email = currentUser?.email || ''
      await axios.post('/api/delete-account', { email });
      navigate('/signin'); // Redirect to sign-in page or home page
    } catch (error) {
      console.error('Delete Account error:', error);
      setErrorMessage('Failed to delete account.');
    }
  };

  return (
    <div className="central-form-container">
      <Typography variant="h4">Account Management</Typography>
      &nbsp;
      <TextField
        label="Email"
        type="email"
        fullWidth
        margin="normal"
        value={currentUser?.email || ''}
        InputProps={{
          readOnly: true,
        }}
      />
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangePassword}
          fullWidth
        >
          Change Password
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          variant="outlined"
          style={{
            color: "#DC3545",
            borderColor: "#DC3545"
          }}
          onClick={() => setOpenDeleteDialog(true)}
          fullWidth
        >
          Delete Account
        </Button>
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Account Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your account? This action cannot be undone and you will have to create a new account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AccountManagement;
