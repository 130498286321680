import { IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";


const Header: React.FC = () => {
  const { isAuthenticated, logoutUser } = useAuth();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:700px)');

  const renderDrawerList = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={() => window.location.href = '/docs'}>
          <ListItemText primary="Documentation" />
        </ListItem>
        <ListItem button onClick={() => navigate('/downloads')}>
          <ListItemText primary="Downloads" />
        </ListItem>
        <ListItem button onClick={() => navigate('/support')}>
          <ListItemText primary="Support" />
        </ListItem>
        <ListItem button onClick={() => navigate('/account')}>
          <ListItemText primary="Account" />
        </ListItem>
      </List>

      <Box sx={{
        display: "flex", 
        justifyContent:"center", 
        position: "absolute", 
        bottom: "0", 
        left: "50%", 
        transform: "translate(-50%, 0)"}}
      >
        {isAuthenticated ? (
          <Button variant="outlined" sx={{m:1, width: .06}} onClick={handleLogout}>Logout</Button>
        ) : (
          <Button variant="outlined" sx={{m:1, width: .06}} onClick={() => navigate('/login')}>Login</Button>
        )}
      </Box>

    </div>
  );

  const toggleDrawer = (open: boolean) => 
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' && 
          ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleLogout = () => {
    logoutUser();
    navigate('/');
  };

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <Link to="/" style={{     minHeight: 80, display: 'flex', alignItems: 'center', color: 'inherit', textDecoration: 'none', flexGrow: 1 }}>
          <img className='logo' src="logo192.png" alt="Logo" style={{ marginRight: '10px' }} />
          <Typography variant="h5" color="inherit" noWrap>
            FEMORPH
          </Typography>
        </Link>

        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ 
            mr: 2, 
            display: { xs: 'block', sm: 'block', md: 'none' }, 
            '@media (min-width:700px)': { display: 'none' } 
          }}

        >
          <MenuIcon />
        </IconButton>

        {!isMobile && (
          <>
            <Button color="inherit" href="/docs">Documentation</Button>
            <Button color="inherit"  component={Link} to="/downloads">Downloads</Button>
            <Button color="inherit"  component={Link} to="/support">Support</Button>
            <Button color="inherit"  component={Link} to="/account">Account</Button>
            {isAuthenticated ? (
              <Button variant="outlined" sx={{m:1, width: .06}} onClick={handleLogout}>Logout</Button>
            ) : (
              <Button variant="outlined" sx={{m:1, width: .06}} onClick={() => navigate('/login')}>Login</Button>
            )}
          </>
        )}
      </Toolbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {renderDrawerList()}
      </Drawer>
    </AppBar>
  );
};

export default Header;
