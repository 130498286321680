import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CircularProgress, Typography, Button, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

interface GitHubArtifact {
  name: string;
  asset_id: number;
}

const Downloads = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [artifacts, setArtifacts] = useState<GitHubArtifact[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [version, setVersion] = useState('');
  const [assetType, setAssetType] = useState('');
  const [os, setOs] = useState('');
  const [pythonVersion, setPythonVersion] = useState('');
  const [availablePythonVersions, setAvailablePythonVersions] = useState<string[]>([]);
  const [availableStandaloneOS, setAvailableStandaloneOS] = useState<string[]>([]);
  const [downloadLink, setDownloadLink] = useState('');
  const [isArtifactsLoading, setIsArtifactsLoading] = useState(false);

  const assetTypeInfo = [
    {
      label: "Documentation",
      value: "Documentation",
      description: "The complete GUI documentation for FEMORPH as a PDF file. Contains detailed information about using the FEMORPH interface."
    },
    {
      label: "Standalone",
      value: "Standalone",
      description: "A self-contained executable package for Linux (packaged as a tarball) and Windows. Requires Ubuntu 18.04 or RHEL 8 for Linux and Windows 10 or newer for Windows."
    },
    {
      label: "Python Wheelhouse",
      value: "PythonWheelhouse",
      description: "Contains all necessary dependencies for installing FEMORPH in an isolated Python environment. Ideal for integration into existing Python setups."
    }
  ];

  const handleVersionChange = (event: SelectChangeEvent) => {
    const selectedTag = event.target.value;
    setVersion(selectedTag);
    setIsArtifactsLoading(true);
    axios.get(`/api/get-release-artifacts/${selectedTag}`)
      .then(response => {
        setArtifacts(response.data.artifacts);
        extractPythonVersions(response.data.artifacts);
      })
      .catch(error => {
        console.error('Error fetching artifacts for selected version:', error);
      })
      .finally(() => {
        setIsArtifactsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    axios.get('/api/get-all-tags')
      .then(tagResponse => {
        const allTags = tagResponse.data.filter((tag: string) => tag.startsWith('v'));
        // Get only the last 10 tags
        const recentTags = allTags.slice(0, 10);
        setTags(recentTags);
        if (recentTags.length > 0) {
          const initialTag = recentTags[0];
          setVersion(initialTag);
          return axios.get(`/api/get-release-artifacts/${initialTag}`);
        } else {
          throw new Error('No valid tags found');
        }
      })
      .then(artifactResponse => {
        setArtifacts(artifactResponse.data.artifacts);
        extractPythonVersions(artifactResponse.data.artifacts);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    // Determine available OS options for Standalone assets
    const standaloneOSOptions = new Set<string>();
    artifacts.forEach(artifact => {
      if (artifact.name.endsWith('.tar.gz')) {
        standaloneOSOptions.add('Linux');
      } else if (artifact.name.endsWith('.exe')) {
        standaloneOSOptions.add('Windows');
      }
    });
    setAvailableStandaloneOS(Array.from(standaloneOSOptions));

    // Auto-populate if only one OS option is available
    const osOptions = Array.from(standaloneOSOptions);
    if (osOptions.length === 1) {
      setOs(osOptions[0]);
    } else {
      setOs(''); // Reset the OS selection when multiple or no options are available
    }

  }, [artifacts]);

  const extractPythonVersions = (artifacts: GitHubArtifact[]) => {
    const versions = new Set<string>();
    artifacts.forEach(artifact => {
      if (artifact.name.includes('wheelhouse')) {
        const match = artifact.name.match(/-(\d+\.\d+)\.zip$/); 
        if (match) versions.add(match[1]);
      }
    });

    const sortedVersions = Array.from(versions).sort((a, b) => {
      const [majorA, minorA] = a.split('.').map(Number);
      const [majorB, minorB] = b.split('.').map(Number);
      if (majorA === majorB) {
        return minorA - minorB;
      }
      return majorA - majorB;
    });

    setAvailablePythonVersions(sortedVersions);
  };

  const updateDownloadLink = () => {
    let filteredArtifacts: GitHubArtifact[] = [];

    if (assetType === 'Standalone') {
      filteredArtifacts = artifacts.filter(a => a.name.endsWith(os === 'Linux' ? '.tar.gz' : '.exe'));
    } else if (assetType === 'PythonWheelhouse') {
      filteredArtifacts = artifacts.filter(a => a.name.includes('wheelhouse') && a.name.includes(pythonVersion) && a.name.includes(os));
    } else if (assetType === 'Documentation') {
      // Assuming the documentation is named as 'femorph_gui.pdf' or similar
      filteredArtifacts = artifacts.filter(a => a.name.endsWith('.pdf'));
    }

    const artifact = filteredArtifacts[0]; // Assuming there's only one artifact per filter
    if (artifact) {
      setDownloadLink(`/artifact/${artifact.asset_id}`);
    }
  };

  useEffect(updateDownloadLink, [artifacts, assetType, os, pythonVersion]);

  const isDownloadEnabled = assetType && (assetType !== 'Standalone' || os) && (assetType !== 'PythonWheelhouse' || (pythonVersion && os));

  const showOverlay = isLoading || isArtifactsLoading;

  return (
    <div>
      {showOverlay && (
        <div className="overlay-container">
          <CircularProgress />
        </div>
      )}

      <div className={`central-form-container ${isArtifactsLoading ? 'loading' : ''}`}>
        <Typography variant="h4" style={{ marginBottom: '20px' }}>Download FEMORPH Assets</Typography>
        <Box sx={{ minWidth: 120, marginBottom: '20px' }}>
          <FormControl fullWidth>
            <InputLabel>Version</InputLabel>
            <Select
              value={version}
              label="Version"
              onChange={handleVersionChange}
            >
              {tags.map(tag => (
                <MenuItem key={tag} value={tag}>{tag}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 120, marginBottom: '20px' }}>
          <FormControl fullWidth>
            <InputLabel>Asset Type</InputLabel>
            <Select
              value={assetType}
              label="Asset Type"
              onChange={e => setAssetType(e.target.value)}
            >
              {assetTypeInfo.map(({ label, value, description }) => (
                <MenuItem key={value} value={value}>
                  <Tooltip title={description} placement="right" arrow>
                    <div style={{ width: '100%' }}>{label}</div>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {assetType === 'Standalone' && availableStandaloneOS.length > 0 && (
          <>
            <Box sx={{ minWidth: 120, marginBottom: '20px' }}>
              <FormControl fullWidth>
                <InputLabel>OS</InputLabel>
                <Select
                  value={os}
                  label="OS"
                  onChange={e => setOs(e.target.value)}
                >
                  {availableStandaloneOS.map(osOption => (
                    <MenuItem key={osOption} value={osOption}>{osOption}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </>
        )}
        {assetType === 'PythonWheelhouse' && (
          <>
            <Box sx={{ minWidth: 120, marginBottom: '20px' }}>
              <FormControl fullWidth>
                <InputLabel>OS</InputLabel>
                <Select
                  value={os}
                  label="OS"
                  onChange={e => setOs(e.target.value)}
                >
                  <MenuItem value="Linux">Linux</MenuItem>
                  <MenuItem value="Windows">Windows</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 120, marginBottom: '20px' }}>
              <FormControl fullWidth>
                <InputLabel>Python Version</InputLabel>
                <Select
                  value={pythonVersion}
                  label="Python Version"
                  onChange={e => setPythonVersion(e.target.value)}
                >
                  {availablePythonVersions.map(version => (
                    <MenuItem key={version} value={version}>{version}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </>
        )}
        <Button variant="contained" color="primary" disabled={!isDownloadEnabled} href={downloadLink}>
          Download
        </Button>
      </div>
    </div>
  );
};

export default Downloads;
