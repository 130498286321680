import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PasswordStrengthBar, {PasswordFeedback} from 'react-password-strength-bar';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import '../components/CentralForm.css'

const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userErrorMessage, setUserErrorMessage] = useState<React.ReactNode>(null);
  const [passwordScore, setPasswordScore] = useState(0);
  const [requestSent, setRequestSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (userErrorMessage) setUserErrorMessage('');
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (errorMessage) setErrorMessage('');
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (errorMessage) setErrorMessage('');
  };

  const handleScoreChange = (score: number, _feedback: PasswordFeedback) => {
    setPasswordScore(score);
  };

  const handleSignUp = async () => {
    if (password.length < 12) {
      setErrorMessage('Password is too short. It must be at least 12 characters.');
      return;
    }

    if (passwordScore < 2) {
      setErrorMessage('Password is too weak.');
      return;
    }
    
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    setIsLoading(true);
    try {      
      await axios.post('/api/signup', { email, password });
      setRequestSent(true);
    } catch (error) {
      if (axios.isAxiosError(error)){
        if (error.response?.status === 409) {
          setUserErrorMessage(
            <>User already exists. Head back to the <Link to="/login">Login Page</Link>.</>
          );
        } else if (error.response?.status === 403) {
          const serverMessage = error.response.data || 'Error during sign up.';
          setUserErrorMessage(serverMessage);
          console.error(serverMessage);
        } else if (error.response) {
          const serverMessage = error.response.data || 'Error during sign up.';
          setErrorMessage(serverMessage);
          console.error('Sign Up error:', serverMessage);
        } else {
          console.error('Sign Up error:', error);
          setErrorMessage('Failed to sign up. Please try again later.');
        }
      } else {
        console.error('Sign Up error:', error);
        setErrorMessage(`Failed to sign up: ${error}`);
      }
    }
    setIsLoading(false);
  };

  // Render loading state
  if (isLoading) {
    return (
      <div style={{ opacity: 0.5 }}>
        <CircularProgress />
      </div>
    );
  }

  if (requestSent) {
    return (
      <div className='central-form-container'>
        <Typography variant="h4">Please Check Your Inbox</Typography>
        <Box mt={2}>
          <Typography align="left">
            We've sent an email with a link to complete your sign up. Please
            check your inbox and follow the instructions to finish setting up
            your account.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography align="left">
            Didn't receive an email? <Link to="#" onClick={handleSignUp}>Resend</Link>
          </Typography>
        </Box>
      </div>
    );
  }

  return (
    <div className='central-form-container' >
      <Typography variant="h4">Sign Up</Typography>
      &nbsp;
      <TextField
        label="Email Address"
        type="email"
        fullWidth
        margin="normal"
        value={email}
        onChange={handleEmailChange}
        error={!!userErrorMessage}
        helperText={userErrorMessage}
      />
      <TextField
        label="Password"
        type="password"
        name="new-password"
        autoComplete="new-password"
        fullWidth
        margin="normal"
        value={password}
        onChange={handlePasswordChange}
        error={!!errorMessage}
        helperText={errorMessage}
      />
      <PasswordStrengthBar
        password={password}
        minLength={12}
        shortScoreWord={"Must be at least 12 characters"}
        onChangeScore={handleScoreChange}
      />
      <TextField
        label="Confirm Password"
        type="password"
        autoComplete="new-password"
        fullWidth
        margin="normal"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        error={!!errorMessage}
      />
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSignUp}
          fullWidth
        >
          Sign Up
        </Button>
      </Box>
    </div>
  );
};

export default SignUp;
