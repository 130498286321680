import { Box, Typography, Button, Paper, Grid, Card, CardContent, CardMedia } from '@mui/material';


export default function MainPage() {
  const handleLearnMoreClick = () => {
    window.location.href = '/docs';
  };

  const features = [
    { title: 'Mesh Morping', description: 'Transform your FEM to match target surfaces from CAD, structured light, or other FEMs.', thumbnail: '/features/thumbnails/morphing.webp'},
    { title: 'Rotor Analysis', description: 'Handle full rotors and individual sectors for cyclic modal analysis.', thumbnail: '/features/thumbnails/rotor_scan.webp'},
    { title: 'Boolean Operations', description: 'Cut holes, notches, and complex features into mesh surfaces.' , thumbnail: '/features/thumbnails/after_cut.webp'},
    { title: 'Scripting', description: 'Record workflows and generate standalone scripts.' , thumbnail: '/features/thumbnails/scripting.webp'},
    { title: 'CAD Integrations', description: 'Import and morph to STEP and IGES models.' , thumbnail: '/features/thumbnails/nist-cad.webp'},
    { title: 'Alignment', description: 'Fully automated and accurate part registration using MAGSAC.' , thumbnail: '/features/thumbnails/align_example_initial.webp'},
  ];

  return (
    <Box sx={{ p: 4 }}>

      {/* Hero Section */}
      <Paper sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h2" gutterBottom>
          FEMORPH - Advanced Mesh Morphing
        </Typography>
        <Typography variant="h6" sx={{ my: 2 }}>
          Elevate finite element modeling with intuitive mesh transformations and digital twin creation.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleLearnMoreClick} sx={{ mt: 4, mb: 4 }}>
          Learn More
        </Button>
        <Box sx={{ my: 2 }}>
          <img src="/main-page.webp" alt="FEMORPH Application" style={{ width: '100%', height: 'auto' }} />
        </Box>
      </Paper>


      {/* Features Section */}
      <Paper sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Key Features
        </Typography>
        <Grid container spacing={2}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6">{feature.title}</Typography>
                  <Typography variant="body2">{feature.description}</Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  src={feature.thumbnail}
                  alt={`Thumbnail for ${feature.title}`}
                  sx={{ mb: 3 }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    
    </Box>
  );
}
